import React, { Suspense } from 'react';
import ScrollToTop from './ScrollToTop';
import { Route, Switch, useLocation } from 'react-router-dom';

import PreNav from './components/PreNav';
import Navigation from './components/Navigation';
import MainWrapper from './pages/MainWrapper';

import Footer from './components/Footer';

import GlobalStyle from './components/GlobalStyle';

//animation
import { AnimatePresence } from 'framer-motion';
import LoadingSpinner from './components/LoadingSpinner';
import { Fragment } from 'react';

const ONama = React.lazy(() => import('./pages/ONama'));
const Usluge = React.lazy(() => import('./pages/Usluge'));
const Kontakt = React.lazy(() => import('./pages/Kontakt'));
const Hvala = React.lazy(() => import('./pages/Hvala'));

const Error404 = React.lazy(() => import('./pages/404'));

function App() {
  const location = useLocation();

  return (
    <Fragment>
      <GlobalStyle />
      <div className='App'>
        <PreNav />
        <Navigation />
        <ScrollToTop />
        <Suspense
          fallback={
            <div className='centered'>
              <LoadingSpinner />
            </div>
          }
        >
          <AnimatePresence exitBeforeEnter>
            <Switch location={location} key={location.pathname}>
              <Route path='/' exact>
                <MainWrapper />
              </Route>

              <Route path='/o-nama' exact>
                <ONama />
              </Route>

              <Route path='/usluge' exact>
                <Usluge />
              </Route>

              <Route path='/kontakt' exact>
                <Kontakt />
              </Route>

              <Route path='/hvala' exact>
                <Hvala />
              </Route>

              <Route>
                <Error404 />
              </Route>
            </Switch>
          </AnimatePresence>
        </Suspense>
        <Footer />
      </div>
    </Fragment>
  );
}

export default App;
