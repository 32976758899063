import React, { useState } from 'react';
import navigationData from '../navigationData/navigationData';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { useLocation } from 'react-router-dom';
import { Line } from './Styles';
import './Navigation.css';
import Logo from '../img/logo-1.svg';

const Navigation = () => {
  const [navActive, setNavActive] = useState(false);
  const [backgroundNav, setBackgroundNav] = useState(false);

  const showNavbar = () => {
    setNavActive(!navActive);
    setBackgroundNav(!backgroundNav);
    document.body.classList.toggle('hidden');
  };
  const removeNavbar = () => {
    setNavActive(false);
    setBackgroundNav(false);
    document.body.classList.remove('hidden');
  };
  const activePathName = useLocation();

  return (
    <StyledHeader>
      <div className='navigation'>
        <nav>
          <div className='logo'>
            <Link to='/'>
              <img src={Logo} alt='logo' />
            </Link>
          </div>

          <div className={`nav-links ${navActive && 'active'}`}>
            <ul>
              {navigationData.map((link) => (
                <li key={link.nameUrl}>
                  <Link to={link.path} onClick={removeNavbar}>
                    {link.nameUrl}
                  </Link>
                  <Line
                    transition={{ duration: 0.3 }}
                    initial={{ width: '0%' }}
                    animate={{
                      width:
                        activePathName.pathname === link.path ? '100%' : '0%',
                    }}
                  />
                </li>
              ))}
            </ul>
          </div>
          <motion.div
            whileHover={{ scale: 1.1 }}
            onClick={showNavbar}
            className={`bars ${navActive && 'active'}`}
          >
            <div className='bar-1'></div>
            <div className='bar-2'></div>
            <div className='bar-3'></div>
          </motion.div>
          <div
            className={`backgroundNav ${backgroundNav && 'active'}`}
            onClick={showNavbar}
          ></div>
        </nav>
      </div>
    </StyledHeader>
  );
};

const StyledHeader = styled.header`
  position: sticky;
  z-index: 9998;
  top: 0;
`;

export default Navigation;
