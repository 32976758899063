import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  
}
.hidden{
  overflow: hidden;
}
.App{
  font-family: 'Open Sans', sans-serif;
  background-color: #F7F7F7;
  display: flex;
  flex-direction: column;
  min-height: 100vh; 
  
}

//wrapper
.wrapper{
  width: 80vw;
  margin: 0 auto;
  height: 100%;
  flex-grow: 3;
  overflow:hidden;
}

.centered {
  margin: 3rem auto;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 40vh)
}

.uppercase{
  text-transform: uppercase;
        
}

::-moz-selection {
  color: #fff;
  background:#5173ad;
}

::selection {
  color: #fff;
  background: #5173ad;
}
p{
  font-size:14px;
  line-height: 30px;
  letter-spacing: 1px;
  margin: 16px 0;
  color:#333;
}
button{
    margin-top: 20px;
    width: 200px;
    height: 50px;
    font-size: 18px;
    background-color: #1c62b8;
    border: 1px solid #12263A;
    color: #fff;
    cursor: pointer;
    transition: all .3s ease;
    outline:none;
    &:hover{
        background-color: #5390d9;
        border-color:#2B3D41
    }
    
}
h3{
  font-size:22px;
  color:#333;
}
a{
    list-style: none;
    text-decoration: none;
    cursor: pointer;
    color: #fff;

}

i{
  color:#333;
}

@media screen and (min-width: 1900px){
  .wrapper{
    max-width: 1197px;
  }
}
@media screen and (min-width: 1200px){
  .wrapper{
    width: 75vw;
  }
}
@media screen and (max-width: 480px){
  .wrapper{
    width: 90vw;
  }
}

`;

export default GlobalStyle;
