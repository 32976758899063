import React from 'react';
import styled from 'styled-components';
import { StyledFlexDiv } from './Styles';

const PreNav = () => {
  return (
    <StyledPreNav>
      <h1>Auto servis MS AUTO Užice</h1>
      <div className='tel'>
        <span>
          <a href='tel:+381641889966' rel='nofollow'>
            064/1889966
          </a>
        </span>
        <span>
          <a href='tel:+381606881000' rel='nofollow'>
            060/6881000
          </a>
        </span>
      </div>
    </StyledPreNav>
  );
};

const StyledPreNav = styled(StyledFlexDiv)`
  overflow: hidden;
  height: 5vh;
  justify-content: space-between;
  flex-wrap: nowrap;
  color: #787a7a;
  width: 95vw;
  margin: 0 auto;
  color: #434545;
  h1 {
    font-size: 16px;
    font-weight: 400;
  }
  span {
    margin: 0 6px;
  }
  .tel {
    margin-right: 30px;
  }
  a {
    color: #434545;
    margin: 6px;
    height: 85px;
    &:hover {
      text-decoration: underline;
    }
  }

  @media (min-width: 2159px) {
    height: 2vh;
    font-size: 14px;
    max-width: 1197px;
  }
  @media (max-width: 700px) and (orientation: landscape) {
    height: 6vh;
    font-size: 14px;
  }
  @media (max-width: 480px) {
    height: 7vh;
    font-size: 14px;
    padding-top: 4px;
    text-align: center;
    .tel {
      margin-right: 0px;
    }
    @media (max-width: 320px) {
      height: 10vh;
    }
  }
`;
export default PreNav;
