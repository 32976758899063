import styled from 'styled-components';
import { motion } from 'framer-motion';
export const StyledPTag = styled.p``;

export const StyledLogo = styled.div`
  img {
    width: 150px;
  }
`;
export const StyledFlexDiv = styled.div`
  display: flex;
  align-items: center;
`;
export const StyledFullWidthDiv = styled.div`
  width: 100vw;
  overflow: hidden;
`;
export const Line = styled(motion.div)`
  height: 3px;
  background: #fff;
  width: 0%;
  position: absolute;
  bottom: 0%;
  left: 0%;
`;
export const StyledSmallLine = styled.div`
  width: 60px;
  height: 3px;
  display: inline-block;
  background-color: #dbdbdb;
  text-align: center;
  margin-bottom: 8px;
`;

// card flex
export const StyledCardsWrapper = styled(motion.div)`
  display: flex;

  justify-content: center;
  align-items: center;
  margin: 60px;
  @media (max-width: 1200px) {
    margin: 60px 0;
  }
`;

export const StyledCards = styled(motion.div)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (max-width: 992px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const StyledSingleCard = styled(motion.div)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 30%;
  flex-wrap: wrap;

  margin: 30px 0;
  &:hover {
    i {
      color: #1c62b8;
    }
  }
  .button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    width: 200px;
    height: 50px;
    font-size: 18px;
    background-color: #1c62b8;
    border: 1px solid #12263a;
    color: #fff;
    cursor: pointer;
    transition: all 0.3s ease;
    outline: none;
    &:hover {
      background-color: #5390d9;
      border-color: #2b3d41;
    }
  }
  i {
    font-size: 40px;
    transition: all 0.3s ease;
  }
  h3 {
    margin: 10px 0;
  }
  p {
    flex: 1 0 auto;
    text-align: center;
  }
  @media (max-width: 992px) {
    width: 100%;
  }
  @media (max-width: 768px) {
    margin-top: 40px;
    width: 100%;
  }
`;

export const StyledInfoText = styled.div`
  text-align: center;
  margin: 50px 0 40px 0;
  h3 {
    margin-bottom: 20px;
  }
  p {
    font-size: 16px;
  }
`;

export const StyledSingleCardService = styled(StyledSingleCard)`
  margin: 20px 0;
  width: 50%;
  &:nth-child(2) {
    padding-left: 40px;
  }

  p {
    text-align: left;
  }
  @media (max-width: 992px) {
    width: 100%;
    &:nth-child(2) {
      padding-left: 0px;
      order: -1;
    }
  }
`;
////////////////////////////////////////////////////////////////// cover slike
export const StyledFullBackgound = styled(motion.div)`
  width: 100%;
  height: 500px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;

  @media (max-width: 768px) {
    height: 400px;
  }
  @media (max-width: 480px) {
    height: 300px;
  }
  @media (max-width: 840px) and (orientation: landscape) {
    height: 300px;
  }
`;
export const StyledFullBackgoundImg = styled(StyledFullBackgound)`
  opacity: 1;

  height: 300px;
  .background-transparent {
    background-color: rgba(0, 0, 0, 0.1);
    z-index: 999;
    height: 300px;
    @media (max-width: 768px) {
      height: 200px;
    }
  }
  @media (max-width: 768px) {
    height: 200px;
  }
`;

/////////////////////////////////////////////////////////////////////// footer

export const StyledFooter = styled.footer`
  p {
    margin: 0;
    color: #fff;
  }
  padding: 30px 12%;
  color: #fff;

  overflow: hidden;
  width: 100%;
  background-color: #222222;
  .flex {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    width: 100%;
    @media screen and (min-width: 1900px) {
      max-width: 1197px;
      .copyng {
        width: 1197px;
      }
    }
  }
  @media (max-width: 768px) {
    padding: 30px 6%;
  }
`;
export const StyledFlexFooter = styled.div`
  margin-bottom: 20px;
  padding-bottom: 30px;
  border-bottom: 1px solid white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .footer-text {
    width: 30%;
  }
  @media (max-width: 992px) {
    flex-direction: column;

    .footer-text {
      width: 100%;
      margin-bottom: 30px;
    }
  }
  @media screen and (min-width: 1900px) {
    max-width: 1197px;
  }
`;
export const StyledFooterInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  @media (max-width: 992px) {
    margin-bottom: 30px;
  }

  a {
    padding-right: 10px;
    &:hover {
      color: #1c63b8;
    }
  }
`;
export const StyledFooterText = styled.div`
  display: flex;

  margin: 10px 10px 0 0;
  a {
    font-size: 14px;
  }
  i {
    margin-right: 15px;
    font-size: 18px;
    color: #1c63b8;
  }
`;
export const StyledSocialMedia = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;

  .uppercase {
    margin-bottom: 10px;
  }
  a {
    display: inline-block;
    margin: 10px 15px 0 0;
    font-size: inherit;

    i {
      color: white;
      font-size: 22px;
      &:hover,
      &:focus {
        color: #1c63b8;
      }
    }
  }
`;

////////////////////////////////////////////////////
// KONTAKT

export const StyledSingleCardForm = styled(StyledSingleCardService)`
  &:hover {
    i {
      color: black;
    }
  }
`;
export const StyledForm = styled(motion.form)`
  width: 80%;
  margin: 0 auto;
  text-align: center;
  .label-input {
    display: flex;
    justify-content: center;
    margin: 20px;
    font-size: 15px;
    align-items: center;
  }
  .required:after {
    content: ' *';
    color: red;
  }
  label {
    flex-grow: 1;
  }
  .label-input.date {
    .react-datepicker-wrapper {
      width: 70%;
    }
  }
  input,
  textarea {
    font-size: 14px;
    width: 70%;
    padding: 7px 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    transition: all 0.27s ease;
    resize: none;
    &:hover,
    &:focus {
      border: 1px solid black;
    }
    outline: none;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
  @media (max-width: 768px) {
    width: 100%;
    label {
      margin-bottom: 10px;
    }
    input,
    textarea {
      width: 100%;
    }
    .label-input {
      margin: 20px 0;
      flex-direction: column;
    }
    .label-input.date {
      .react-datepicker-wrapper {
        width: 100%;
      }
    }
  }
`;
export const StyledCardForm = styled(motion.div)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  p {
    margin: 10px 20px;
  }

  .row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 10px 0;

    &:hover {
      i {
        color: red;
        color: #1c63b8;
      }
    }
    a {
      &:hover,
      &:focus {
        color: #1c63b8;
      }
    }
    a {
      list-style: underline;
      margin-right: 10px;
      color: black;

      &:hover {
        text-decoration: underline;
      }
    }
  }
  @media (max-width: 480px) {
    .row {
      p {
        width: 80%;
      }
    }

    i {
      font-size: 32px;
    }
  }
`;

/////////////////////////////////
// ZAKAZIVANJA

export const StyledNumbgerTermin = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border: 1px solid #ccc;
  border-radius: 50%;
  margin: 0 auto;
`;
export const StyledCardsTermin = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 50px;
  .flexTermin {
    margin-bottom: 50px;
  }
  @media (max-width: 992px) {
    flex-direction: column;
    width: 100%;
    .flexTermin {
      margin: 0 auto 50px auto;
    }
  }
`;
export const StyledSingleCardTermin = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
export const StyledCardsTerminInfo = styled(motion.div)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  div {
    transition: all 0.3s ease;
  }

  &:hover {
    div {
      background-color: #1c62b8;
      color: white;
    }
  }
  p {
    width: 70%;
  }
`;
