import React, { useState } from 'react';

import {
  StyledSmallLine,
  StyledLogo,
  StyledFooter,
  StyledFlexFooter,
  StyledFooterInfo,
  StyledFooterText,
  StyledSocialMedia,
} from './Styles';

import Logo from '../img/logo-1.svg';

const Footer = () => {
  const [mailto, setMailto] = useState('');
  const replaceMail = () => {
    setMailto('msautouzice@gmail.com');
  };
  return (
    <StyledFooter>
      <div className='flex'>
        <StyledFlexFooter>
          <div className='footer-text'>
            <StyledLogo>
              <img src={Logo} alt='logo' />
            </StyledLogo>
          </div>

          <StyledFooterInfo>
            <div className='uppercase'>
              <p>Kontaktirajte nas</p>
              <StyledSmallLine style={{ backgroundColor: 'red' }} />
            </div>

            <StyledFooterText>
              <i className='fas fa-map-marker-alt'></i>

              <p>
                <a
                  href='https://www.google.com/maps/place/MS+Auto+Servis/@43.8494694,19.8545884,19z/data=!4m5!3m4!1s0x4759d3ea4f8af71f:0x68f675c7f9f15384!8m2!3d43.8495413!4d19.8548564?hl=sr'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Miloša Obrenovića br. 2, Krčagovo-Užice
                </a>
              </p>
            </StyledFooterText>
            <StyledFooterText>
              <i className='fas fa-phone-alt'></i>

              <p className='tel'>
                <a href='tel:+381641889966' rel='nofollow'>
                  064/1889966
                </a>
                <a href='tel:+381606881000' rel='nofollow'>
                  060/6881000
                </a>
              </p>
            </StyledFooterText>
            <StyledFooterText>
              <i className='fas fa-pencil-alt'></i>

              <a
                href={mailto ? `mailto:${mailto}` : null}
                onClick={replaceMail}
              >
                Pošalji email
              </a>
            </StyledFooterText>
          </StyledFooterInfo>
          <StyledSocialMedia>
            <div className='uppercase'>društvene mreže</div>
            <StyledSmallLine style={{ backgroundColor: 'red' }} />
            <div>
              <a
                aria-label='facebook page'
                href='https://www.facebook.com/Autoservis-MS-AUTO-Užice-103448938465052'
                target='_blank'
                rel='noopener noreferrer'
              >
                <i className='fab fa-facebook'></i>
              </a>
              {/* <a aria-label='instagram page' href="https://www.instagram.com/" target="_blank" rel="noopener noreferrer">
                            <i className="fab fa-instagram"></i>
                        </a> */}
            </div>
          </StyledSocialMedia>
        </StyledFlexFooter>

        <div className='copyng'>
          <p>
            {new Date().getFullYear()}. &copy; Auto servis MS AUTO Global Užice.
            Sva prava zadržana.
          </p>
        </div>
      </div>
    </StyledFooter>
  );
};

export default Footer;
