const navigationData = [
  {
    path: '/',
    nameUrl: 'Početna',
    id: 1,
  },
  {
    path: '/o-nama',
    nameUrl: 'O Nama',
    id: 2,
  },
  {
    path: '/usluge',
    nameUrl: 'Usluge',
    id: 3,
  },
  {
    path: '/kontakt',
    nameUrl: 'Kontakt',
    id: 4,
  },
];

export default navigationData;
