import React from 'react';
import Slider from '../slider/Slider';

import Cards from '../components/Cards';
import { Helmet } from 'react-helmet';

//animations
import { motion } from 'framer-motion';
import { fade } from '../Animation';

const MainWrapper = () => {
  return (
    <motion.main
      className='main-wrapper'
      variants={fade}
      initial='hidden'
      animate='show'
      exit='exit'
    >
      <Helmet>
        <title>Auto servis MS AUTO</title>
        <meta name='title' content='Auto servis MS AUTO | Početna'></meta>
        <meta
          name='description'
          content='Auto servis MS AUTO Užice vrši popravke svih modela vozila. Mi nudimo usluge svih vidova mehanike, auto dijagnostike i servisiranja vozila'
        />

        <meta property='og:type' content='website' />
        <meta property='og:url' content='https://msautoservisuzice.rs' />
        <meta property='og:title' content='Auto servis MS AUTO | Početna' />
        <meta
          property='og:description'
          content='Auto servis MS AUTO Užice vrši popravke svih modela vozila. Mi nudimo usluge svih vidova mehanike, auto dijagnostike i servisiranja vozila'
        />
        <meta
          property='og:image:url'
          content='https://msautoservisuzice.rs/static/media/0023.5a0d7580.jpg'
        />
        <meta property='og:image:type' content='image/jpeg' />
        <meta property='og:image:width' content='300' />
        <meta property='og:image:height' content='300' />
      </Helmet>

      <Slider />
      <div className='wrapper'>
        <Cards />
      </div>
    </motion.main>
  );
};

export default MainWrapper;
