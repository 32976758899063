import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import styled from 'styled-components';
import { v4 as uuid } from 'uuid';
import { Link } from 'react-router-dom';

import img1 from '../img/0023.jpg';
import img2 from '../img/0024.jpg';
import img3 from '../img/002.jpg';
import img4 from '../img/0025.jpg';

//animation
import { motion } from 'framer-motion';
import { titleAnimation, photoAnimationSmallScale } from '../Animation';

const Slider = () => {
  const items = [
    {
      title: 'AUTO SERVIS MS AUTO Užice',
      boldText: '',
      pTag: 'Najbolji prijatelj Vašeg automobila',
      imgSrc: img1,
      buttonText: 'O nama',
      url: '/o-nama',
    },
    {
      title: 'USLUGE - MS AUTO SERVIS',
      boldText: '',
      pTag: 'Obavljamo usluge popravke i održavanja Vašeg vozila',
      imgSrc: img2,
      buttonText: 'Naše usluge',
      url: '/usluge',
    },
    {
      title: 'KONTAKTIRAJTE NAS',

      pTag: 'Pogledajte gde se nalazimo',
      imgSrc: img3,
      buttonText: 'Kontakt',
      url: '/kontakt',
    },
  ];

  return (
    <StyledCarousel style={{ background: 'rgba(0,0,0,0.5)' }}>
      <Carousel
        swipeable={false}
        showArrows={true}
        infiniteLoop={true}
        showStatus={false}
        autoPlay={true}
        interval={6000}
        showThumbs={false}
      >
        {items.map((item) => (
          <StyledCarouselItem
            key={uuid()}
            style={{ backgroundImage: 'url(' + item.imgSrc + ')' }}
          >
            <StyledCarouselText>
              <motion.h2 variants={titleAnimation}>{item.title}</motion.h2>
              <motion.h3 variants={titleAnimation}>{item.boldText}</motion.h3>
              <motion.p variants={titleAnimation}>{item.pTag}</motion.p>
              <Link to={item.url}>
                <motion.button variants={photoAnimationSmallScale}>
                  {item.buttonText}
                </motion.button>
              </Link>
            </StyledCarouselText>
          </StyledCarouselItem>
        ))}
      </Carousel>
    </StyledCarousel>
  );
};

const StyledCarousel = styled.div`
  .carousel .control-dots .dot {
    width: 12px;
    height: 12px;
    background: #fff;
    @media only screen and (max-width: 480px) {
      margin-bottom: 10px;
    }
  }
  .carousel .control-arrow {
    display: absolute;
    height: 70px;
    width: 30px;
    margin: auto 5px;
  }
`;
const StyledCarouselItem = styled.div`
  height: 75vh;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  overflow: hidden;
  font-size: 24px;

  @media only screen and (max-width: 480px) {
    height: 80vh;
  }
  @media (min-width: 2160px) {
    height: 50vh;
  }
`;

const StyledCarouselText = styled(motion.div)`
  position: absolute;
  top: 50%;
  color: #fff;
  left: 20vw;
  text-align: left;
  font-size: 22px;
  text-shadow: 4px 4px 4px #000;

  h3 {
    margin-top: 20px;
    color: #fff;
  }
  p {
    margin: 20px 0;
    font-size: 18px;
    color: #fff;
  }
  button {
    text-transform: uppercase;
  }
  @media only screen and (max-width: 768px) {
    left: 7vw;
  }

  @media only screen and (max-width: 480px) {
    top: 30%;
    color: #fff;
    left: 10vw;
    width: 80%;
  }
  @media (max-width: 840px) and (orientation: landscape) {
    top: 8%;
    left: 7vw;
    font-size: 20px;
    p {
      margin-top: 10px;
    }
  }
  @media screen and (max-width: 480px) and (orientation: landscape) {
    font-size: 14px;
    top: 5%;
  }
  @media (max-width: 320px) {
    top: 10%;
    font-size: 14px;
  }
  @media (min-width: 2159px) {
    left: 25%;
  }
  @media (min-width: 4320px) {
    left: 35%;
  }
`;
export default Slider;
