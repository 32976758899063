import React from 'react';
import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import styled from 'styled-components';
import {
  StyledSmallLine,
  StyledCardsWrapper,
  StyledCards,
  StyledFullBackgound,
  StyledSingleCard,
  StyledInfoText,
  StyledSingleCardService,
} from './Styles';
import imgServiceBackground from '../img/0019.jpg';

const Cards = () => {
  const cards = [
    {
      logo: <i className='fas fa-cogs'></i>,
      title: 'Mehanika',
      text: 'Auto servis je opremljen najmodernijom opremom za servisiranje vozila. Naši majstori imaju višegodišnje iskustvo u radu, što garantuje kvalitet automehaničarskih usluga.',
      button: 'Više informacija',
    },
    {
      logo: <i className='fas fa-car-battery'></i>,
      title: 'Dijagnostika',
      text: 'Najsavremenija kompjuterska tehnologija, čime MS AUTO raspolaže, omogućava sigurnu i preciznu detekciju kvara na vašem vozilu.',
      button: 'Više informacija',
    },
    {
      logo: <i className='fas fa-car'></i>,
      title: 'Održavanje vozila',
      text: 'Redovno održavanje vozila je najvažniji faktor za ispravnost i sigurnu vožnju. Iskustvo servisera omogućava da redovnim održavanjem sprečite mogućnost pojave većih kvarova.',
      button: 'Više informacija',
    },
  ];
  return (
    <Fragment>
      <StyledCardsWrapperColumn>
        <StyledInfoText>
          <h3>Usluge koje obavljamo za vas</h3>
          <StyledSmallLine />
          <p>
            MS Auto servis vrši auto-dijagnostiku, servisiranje motora, zamene
            ulja, mali i veliki servis i druge usluge koje su neophhodne za
            održavanje vozila.
          </p>
        </StyledInfoText>

        <StyledCards>
          {cards.map((card) => (
            <StyledSingleCard key={uuid()}>
              <div>{card.logo}</div>
              <h3>{card.title}</h3>
              <p>{card.text}</p>
              <Link className='button' to='/usluge' exact>
                {card.button}
              </Link>
            </StyledSingleCard>
          ))}
        </StyledCards>
      </StyledCardsWrapperColumn>

      <StyledCardsService>
        <StyledSingleCardService>
          <StyledFullBackgoundImg
            style={{ backgroundImage: 'url(' + imgServiceBackground + ')' }}
          />
        </StyledSingleCardService>
        <StyledSingleCardService>
          <h3>ZAŠTO IZABRATI NAS?</h3>
          <StyledSmallLine />
          <p>
            Auto servis MS Auto Užice pruža svojim klijentima širok spektar
            usluga kako bi Vaše vozilo bilo bezbedno na putu.
          </p>
        </StyledSingleCardService>
      </StyledCardsService>
    </Fragment>
  );
};

const StyledCardsWrapperColumn = styled(StyledCardsWrapper)`
  flex-direction: column;
`;

const StyledCardsService = styled(StyledCards)`
  width: 100%;
  margin-bottom: 50px;
`;

const StyledFullBackgoundImg = styled(StyledFullBackgound)`
  min-height: 400px;
`;

export default Cards;
